import React, { useState } from 'react';
import {Button} from 'react95';
import { ThemeProvider } from "styled-components";
import original from 'react95/dist/themes/original';
import KickAss from '../Media/Images/kickass.webp';
import { Images } from '../Utility/Images';

export const PageNotFound: React.FC<any> = (props: any) => {

    const [eng, setEng] = useState(true);

    return (
        <div style={{ width: "100vw", height: "100vh", backgroundImage: "url('" + Images.BACKGROUND_SKETCHES + "')", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <div style={{ fontFamily: "'MyFont'", color: "white", fontSize: "2rem", display: "block", textAlign: "center" }}>
                {eng ? <><p style={{fontSize:"3.5rem"}}>
                        <b>404 - NOT FOUND</b>
                    </p>
                    <p>Hey man, where are you going?
                    </p>
                    <p>There's nothing to see here
                    </p>
                    <ThemeProvider theme={original}>
                    <Button onClick={() => {
                        setEng(false);
                        setTimeout(()=>window.location.href = "/edits",2500)
                        }}>Help</Button>
                    </ThemeProvider></>: <img style={{width:"40vw"}} src={KickAss}></img>}
            </div>
        </div>
    )

}