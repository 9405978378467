import React, { useState, useEffect } from 'react';
import { Icons } from '../Utility/Icons';
import { BackButton } from '../Component/BackButton';
import { ComputerScreen } from '../Component/ComputerScreen';
import { LoadingImg } from '../Component/LoadingImg';
import { DemoReelImages } from '../Utility/DemoReelImages';
import { preloadImages, preloadVideo } from '../Utility/Utility';
import { Images, FallingPhoneImages } from '../Utility/Images';
import { BrokeProjectImages, HumanProjectImages, RadioProjectImages, SoupProjectImages } from '../Utility/ProjectLogos';
import { InfoImages } from './Info';
import { Videos } from '../Utility/Videos';

import looping from '../Media/Images/DemoReelImages/LOOPINGtape2.gif';
import DRstop from "../Media/Images/DemoReelImages/OFF.png";
import DRon from "../Media/Images/DemoReelImages/1.png";

type ProjectsProps = {
    backgroundImage?: string;
    children?: React.ReactNode;
};

const SubPage: React.FC<ProjectsProps> = ({ backgroundImage, children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState<HTMLImageElement[]>([]);
    const [finish, setFinish] = useState(false);
    const [showmail, setShowMail] = useState(false);

    const checkFin = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        const url = window.location.href.split('/')[window.location.href.split('/').length - 1];

        let toLoad: string[] = [""];
        if (url == "projects") toLoad = SoupProjectImages.concat(HumanProjectImages, BrokeProjectImages, RadioProjectImages);
        else if (url == "voiceovers") toLoad = [looping, DRstop, DRon];
        else if (url == "sketches") toLoad = FallingPhoneImages;
        else if (url == "info") toLoad = InfoImages;
        else toLoad = Object.values(Images);

        setFinish(true);

        /*const preload = async () => {
            if (url == "sketches") await preloadVideo([Videos.sketchesVideo]);
            const a = await preloadImages(toLoad, setLoaded);
            console.log(a);
            setFinish(true);
        }*/

        //preload();
    }, []);


    const containerStyle: React.CSSProperties = {
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${backgroundImage ?? ""})`,
        backgroundSize: "100vw 100vh",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    return (
        <ComputerScreen>
            {!isLoading && finish ?
                <div style={containerStyle}>
                    <div style={{zIndex:"9999",left:"10px",top:"10px",position:"absolute"}}>
                        <BackButton action={() => (window.location.href = "/")} position="l" icon={Icons.BackButton2} icon2={Icons.BackButton} size={50} />
                        <BackButton action={() => (window.history.back())} position="l" icon={Icons.HomeButton} icon2={Icons.HomeButton2} size={50} />
                        {window.location.pathname == "/info" && <BackButton action={() => setShowMail(!showmail)} position="l" icon={Icons.MailA} icon2={Icons.MailB} size={50} />}
                    </div>
                    {showmail && 
                        <div style={{zIndex:"9999",boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.8)",backgroundColor: "rgba(200,200,200,0.97)",fontFamily:"'MyFont'",display:"flex",fontSize:"2rem",justifyContent:"center", alignItems:"center",position:"absolute",width:"80%", height:"75%"}}>
                            <img onClick={()=>setShowMail(false)} src={Icons.XClose} style={{cursor:"url('"+Icons.PointerHand+"'),auto",width:"4%",aspectRatio:"1", right:0,top:0,position:"absolute"}}></img>
                            <div onClick={()=>window.open("mailto:eric.estevez003@gmail.com")} style={{cursor:"url('"+Icons.PointerHand+"'),auto"}}>eric.estevez003@gmail.com</div>
                        </div>}
                    {children}
                </div>
                : <LoadingImg isFin={checkFin} />
            }
        </ComputerScreen>
    );
};

export default SubPage;
