import React, { useState, useEffect } from "react";
import { BackStars } from "../Utility/Images";

export const StarsBackground: React.FC<any> = (props: any) => {

    const [backStars, setBackStars] = useState(0);
    const [tiles, setTiles] = useState(new Array(8).fill(0));
    var node: React.ReactElement = <img style={{ verticalAlign: "bottom", width: "50vw" }} src={BackStars[backStars]}></img>

    useEffect(() => {
        setTimeout(() => {
            if (backStars == BackStars.length - 1)
                setBackStars(0);
            else setBackStars(backStars + 1);
        }, 500)
    }, [backStars]);

    //review this
    useEffect(() => {
        const handleResize = (event:any) => {
            const w = event.target.innerWidth;
            const h = event.target.innerHeight;
            const r = 1.32;
            setTiles(new Array(2*Math.ceil(h/(w/(2*r)))).fill(0));
        };

        window.addEventListener('resize', handleResize);

        handleResize({target: window});

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{ position: "absolute", overflow: "hidden", width: "100vw", height: "100vh" }}>
            {tiles.map(()=>node)}
        </div>)
}