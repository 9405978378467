import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../Utility/Constants";
import { FeelingOk } from "./FeelingOK";
import { Images } from "../Utility/Images";
import { Icons } from "../Utility/Icons";

export const FrameForVideo: React.FC<any> = (props: any) => {
    const action = !props.controls ?? false ? () => window.location.href = "/fullscreenvideo?url=" + props.url : undefined;

    const [size, setSize] = useState([0, 0]);
    const [show, setShow] = useState("");
    const [windowImg, setWindowImg] = useState("");

    const [windowStyle, setWindowStyle] = useState<React.CSSProperties>({});
    const [videoStyle, setVideoStyle] = useState<React.CSSProperties>({});

    const [mult, setMult] = useState(1);

    useEffect(() => {
        const handleResize = (event:any) => {
            var ww = event.target.innerWidth;
            if(ww > 1200) setMult(1);
            else if(ww > 800) setMult(2);
            else if(ww > 600) setMult(3);
            else if(ww > 300) setMult(4);
        };

        window.addEventListener('resize', handleResize);

        handleResize({target: window});

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(size);
        const w = size[0];
        const h = size[1];
        const winHeight = (400/mult).toString() + "px";
        const vidHeight = (0.75*(w/h)*400/mult).toString() + "px";
        const top = (50/mult) + "px";
        setVideoStyle({ top:top,width:vidHeight });
        setWindowStyle({ height: winHeight })

        if (h != 0) {
            if (w / h > 2)
                setWindowImg(Images.WINDOW_IMAGE_LARGE);
            else if (w / h > 1.7)
                setWindowImg(Images.WINDOW_IMAGE);
            else if (w / h > 1.3) 
                setWindowImg(Images.WINDOW_IMAGE_43);
            else
                setWindowImg(Images.WINDOW_IMAGE_SQUARE);
        }
    }, [size, mult]);

    return (
        props.url != "" ? <div style={{ ...props.style, position:"relative",display: show, cursor: "url('" + Icons.PointerHand + "'), auto", justifyContent:"center" }}>
            <div style={{ position: "relative" }}>
                <video
                    onLoadedMetadata={(event: any) => { setSize([event.target.videoWidth, event.target.videoHeight]) }}
                    onLoadedData={() => setShow("flex")}
                    controls={props.controls ?? false}
                    onClick={action}
                    style={{ ...videoStyle, position: "absolute" }}
                >
                    <source src={props.url + "#t=1.2"} />
                </video>
                <img style={{ pointerEvents: "none", position: "relative", ...windowStyle }} src={windowImg} />
                {show == "flex" && <div style={{position:"absolute", top: (8/mult)+"px", left:(30/mult)+"px",color:"white", fontSize:(20/mult)+"px",fontFamily:"'MyFont'"}}>{props.url.split('/')[props.url.split('/').length - 1].split('?')[0].split('.')[0].replaceAll("-", " ")}</div>}
            </div>
        </div>
            :
            <FeelingOk />
    );
};
