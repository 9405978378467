import React, { useEffect, useState } from 'react';
import Info1 from '../Media/Images/InfoA.png';
import Info2 from '../Media/Images/InfoB.png';
import Info3 from '../Media/Images/InfoC.png';
import UP0 from '../Media/Images/UPonB.png';
import UP1 from '../Media/Images/UPonA.png';
import UP2 from '../Media/Images/UPoff.png';
import DW0 from '../Media/Images/DOWNonB.png';
import DW1 from '../Media/Images/DOWNonA.png';
import DW2 from '../Media/Images/DOWNoff.png';
import PIC1 from '../Media/Images/PIC1.png';
import PIC2 from '../Media/Images/PIC2.png';
import PIC3 from '../Media/Images/PIC3.png';

import defaultPICArrow from '../Media/Images/ArrowPICdefault.png';
import activePICArrow from '../Media/Images/ArrowPICactive.png';

import { Audios } from '../Utility/Audios';
import { Icons } from '../Utility/Icons';

export const InfoImages: string[] = [
    Info1,Info2,Info3,
    UP0,UP1,UP2,
    DW0,DW1,DW2
]

export const Info: React.FC<{}> = () => {

    const images: string[] = [Info1, Info2, Info3];
    const pics: string[] = [PIC1, PIC2, PIC3];

    const [index, setIndex] = useState(0);
    const [updw, setUpDw] = useState(true);
    const [upIm, setUpIm] = useState(UP2);
    const [dwIm, setDwIm] = useState(DW2);
    const [up, setUp] = useState(false);
    const [dw, setDown] = useState(false);

    const [index2, setIndex2] = useState(0);
    const [over, setOver] = useState(false);

    useEffect(() => {
        setTimeout(() => { if (!updw) setUpIm(upIm == UP1 ? UP0 : UP1) }, 500)
        setTimeout(() => { if (updw) setDwIm(dwIm == DW1 ? DW0 : DW1) }, 500)
    }, [dwIm, upIm]);

    useEffect(() => {
        if(over) setTimeout(() => setOver(false), 1000);
    }, [over]);

    const handleSetUpDw = (up: boolean) => {
        if (up) {
            setUpDw(false);
            setTimeout(() => setDwIm(DW2), 500);
            setDown(false);
        }
        else {
            setUpDw(true);
            setTimeout(() => setUpIm(UP2), 500);
            setUp(false);
        }
    }

    const manageup = () => {
        if (index != 0) setUp(!up);
        else setUp(false);
    }
    const manageupcl = () => {
        if (index > 0) {
            (document.getElementById("enableDirection") as HTMLAudioElement).play();
            setIndex(index - 1);
            if (index == 1) handleSetUpDw(false)
        }
        else
            (document.getElementById("disableDirection") as HTMLAudioElement).play();
    }
    const managedwcl = () => {
        if (index < (images.length - 1)) {
            (document.getElementById("enableDirection") as HTMLAudioElement).play();
            setIndex(index + 1);
            if (index == images.length - 2) handleSetUpDw(true)
        }
        else
            (document.getElementById("disableDirection") as HTMLAudioElement).play();
    }

    const managedown = () => {
        if (index < (images.length - 1)) setDown(!dw);
        else setDown(false);
    }

    const nextPic = () => {
        (document.getElementById("enableDirection") as HTMLAudioElement).play();
        if(index2 < pics.length-1) setIndex2(index2+1);
        else setIndex2(0);
    }


    return (
        <div style={{ width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <div style={{ marginLeft: "2.5vw", width:"60%",display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ position: "relative" }}>
                    <img style={{ width: "100%" }} src={images[index]} />
                    <img src={up ? UP1 : upIm} onClick={manageupcl} onMouseOver={manageup} onMouseLeave={manageup} style={{ cursor: "url('" + Icons.PointerHand + "'),auto", width: "2vw", height: "2vw", position: "absolute", right: "10.6%", top: "9%" }}></img>
                    <img src={dw ? DW1 : dwIm} onClick={managedwcl} onMouseOver={managedown} onMouseLeave={managedown} style={{ cursor: "url('" + Icons.PointerHand + "'),auto", width: "2vw", height: "2vw", position: "absolute", right: "10.6%", bottom: "22%" }}></img>
                </div>
            </div>
            <div style={{ marginLeft: "2.5vw", width:"15%",display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ position: "relative" }}>
                    <img style={{ width: "100%" }} src={pics[index2]} />
                    <img src={over ? activePICArrow : defaultPICArrow} onClick={nextPic} onMouseLeave={()=>setOver(false)} onMouseOver={()=>setOver(true)} style={{cursor: "url('" + Icons.PointerHand + "'),auto", width: "2vw", height: "2vw", position: "absolute", right: "30%", bottom: "22%" }}></img>
                </div>
            </div>
            <audio style={{ display: "none" }} id="enableDirection" src={Audios.EnDi}></audio>
            <audio style={{ display: "none" }} id="disableDirection" src={Audios.DiDi}></audio>
        </div>
    );
} 