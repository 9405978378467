import React, { useState, useEffect } from "react";
import HumanBack from '../Media/Images/HumanBACK.png';

type ProjectBackgroundProps = {
    src: string;
    children: React.ReactNode;
    items: number[],
    numitems: number
}

export const ProjectBackground: React.FC<ProjectBackgroundProps> = ({src,items,numitems,children}) => {

    const [tiles, setTiles] = useState(new Array(Math.ceil(items[1]/(items[0]/(3)))).fill(0));
    var node: React.ReactElement = <img style={{ verticalAlign: "bottom", width: "100vw" }} src={src}></img>

    useEffect(() => {
        setTiles(new Array(Math.max(Math.ceil(items[1]/(items[0]/(3))),Math.ceil(numitems))).fill(0))
    },[items])

    return (
        <div id="stipsi" style={{ width:"100vw",height:"100vh",position: "absolute", overflowY:"scroll",scrollbarWidth:"none",overflowX: "hidden"}}>
            <div style={{display:"block",position:"absolute"}}>{tiles.map(()=>node)}</div>
            {children}
        </div>)
}