import React, { useEffect } from 'react';
import { Icons } from '../Utility/Icons';
import { CONSTANTS } from '../Utility/Constants';
import { FrameForVideo } from '../Component/FrameForVideo';
import { useSearchParams } from 'react-router-dom';
import { EditsList, VoiceoverList } from '../Utility/ContentList';

import SoupBack from '../Media/Images/SoupBACK.png';
import MofoBack from '../Media/Images/MofoBACK.png';
import HumanBack from '../Media/Images/HumanBACK.png';
import BrokeBack from '../Media/Images/BrokeBACK.png';
import { Images } from '../Utility/Images';
import { AudioWindow } from '../Component/AudioWindow';
import { ProjectBackground } from '../Component/ProjectBackground';
import { ConvertDropboxUrl } from '../Utility/Utility';
import { FrameForProjects } from '../Component/FrameForProject';

declare const MofoListBase: string[];
declare const SoupListBase: string[];
declare const BrokeListBase: string[];
declare const HumanListBase: string[];

export const ProjectPage: React.FC<any> = (props: any) => {

    const [size, setSize] = React.useState([10, 10]);

    const backToUse = (str: string | null) => {
        switch (str) {
            case "MOFO MAGIC RADIO":
                return MofoBack;
            case "SOUP SERIES":
                return SoupBack;
            case "BROKE HISTORY":
                return BrokeBack;
            case "HUMAN SHOW":
                return HumanBack;
            default:
                return "";
        }
    }

    const itemsCount = (str: string | null) : number => {
        switch (str) {
            case "MOFO MAGIC RADIO":
                return 1 * contents.length;
            case "SOUP SERIES":
                return 1.5 * contents.length;
            case "BROKE HISTORY":
                return 1.5 * contents.length;
            case "HUMAN SHOW":
                return 1.5 * contents.length;
            default:
                return 1;
        }
    }

    const listToUse = (str: string | null) => {
        switch (str) {
            case "MOFO MAGIC RADIO":
                return MofoListBase === undefined ? ["",""] : ConvertDropboxUrl(MofoListBase);
            case "SOUP SERIES":
                return SoupListBase === undefined ? ["",""] : ConvertDropboxUrl(SoupListBase);
            case "BROKE HISTORY":
                return BrokeListBase === undefined ? ["",""] : ConvertDropboxUrl(BrokeListBase);
            case "HUMAN SHOW":
                return HumanListBase === undefined ? ["",""] : ConvertDropboxUrl(HumanListBase);
            default:
                return ["",""];
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerWidth, window.innerHeight]);
            
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [searchParams, setSearchParams] = useSearchParams();
    const back = backToUse(searchParams.get('proj'));
    const contents = listToUse(searchParams.get('proj'));

    const generateRandomOffset = () => {
        const maxOffset = 50; // Maximum offset percentage
        return Math.random() * maxOffset - maxOffset / 2;
    };

    const generateRandomVertOffset = () => {
        const maxOffset = 15; // Maximum offset percentage
        return Math.random() * maxOffset + 5;
    };

    const generateFrames = (list: any) => {
        return list.map((edits: string, index: number) => (
            <div
                key={index}
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                {searchParams.get('proj') == "MOFO MAGIC RADIO" ? 
                    <AudioWindow
                        keyId={index}
                        src={edits}
                        style={{ left: generateRandomOffset().toString() + "vw", marginTop: index == 0 ? Math.abs(generateRandomVertOffset()).toString() + "vh" : generateRandomVertOffset().toString() + "vh"}}
                    />
                :   <FrameForProjects
                        controls
                        key={index}
                        url={edits}
                        style={{ left: generateRandomOffset().toString() + "vw", marginTop: index == 0 ? Math.abs(generateRandomVertOffset()).toString() + "vh" : generateRandomVertOffset().toString() + "vh"}}
                    />
                }
            </div>
        ));
    };

    return (
        <div style={{overflowY: "scroll",overflowX: "hidden",width: "100vw", height: "100vh", color: "white", display: "block", justifyContent: "center" }}>
                <ProjectBackground items={size} numitems={itemsCount(searchParams.get('proj'))} src={back}>
                {generateFrames(contents)}
                </ProjectBackground>
        </div>
    )

}