import React, { useState } from 'react';
import { Images } from '../Utility/Images';
import videoSart from '../Media/Videos/sartoribraidostudio.mp4';

export const SartoriBraidoStudio: React.FC<any> = (props: any) => {

    const managePlay = (event:any) => {
        (document.getElementById("videoSartoriMp4") as HTMLVideoElement).play();
    }

    return (
        <div onClick={(event: any) => managePlay(event)} style={{ overflow: "hidden", display: "flex", position: "relative", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: "50vh", width: "50vw", top: "29vh", backgroundColor: "black", position: "absolute" }}></div>
            <video id="videoSartoriMp4" autoPlay onEnded={() => window.history.back()} style={{ height: "50vh", top: "29vh", position: "absolute" }}>
                <source src={videoSart}></source>
            </video>
            <img style={{ position: "relative", height: "100vh" }} src={Images.SARTORI_BRAIDO_STUDIO}>
            </img>
        </div>
    )
}