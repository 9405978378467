import React, { useEffect, useState } from "react";
import { Images } from "../Utility/Images";
import { CONSTANTS } from "../Utility/Constants";

export const FeelingOk: React.FC<any> = (props: any) => {

    const [overYes, setOverYes] = useState("rgba(0,0,0,0)");
    const [overNo, setOverNo] = useState("rgba(0,0,0,0)");

    const [mult, setMult] = useState(1);

    const [width, setWidth] = useState(CONSTANTS.FEELINGOK_WIDTH);

    useEffect(() => {
        const handleResize = (event:any) => {
            var ww = event.target.innerWidth;
            if(ww > 1200) setMult(1);
            else if(ww > 800) setMult(2);
            else if(ww > 600) setMult(3);
            else if(ww > 300) setMult(4);
        };

        window.addEventListener('resize', handleResize);

        handleResize({target: window});

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=> {
        setWidth(CONSTANTS.FEELINGOK_WIDTH/mult);
    },[mult])

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "50vw" }}>
            <div style={{ position: "relative" }}>
                <img style={{ width: width.toString() + "px", position: "relative" }} src={Images.FEELINGOK_IMAGE}></img>
                <div onMouseOver={() => setOverYes("rgba(0,0,0,0.3)")} onMouseOut={() => setOverYes("rgba(0,0,0,0)")} onClick={() => window.location.href = crypto.randomUUID().toString()} style={{ position: "absolute", backgroundColor: overYes, top: (129/mult).toString()+"px", left: (287/mult).toString()+"px", width: (92/mult).toString()+"px", height: (25/mult).toString()+"px" }}></div>
                <div onMouseOver={() => setOverNo("rgba(0,0,0,0.3)")} onMouseOut={() => setOverNo("rgba(0,0,0,0)")} onClick={() => window.location.href = "/getwellsoon"} style={{ backgroundColor: overNo, position: "absolute", top: (129/mult).toString()+"px", left: (388/mult).toString()+"px", width: (92/mult).toString()+"px", height: (25/mult).toString()+"px"  }}></div>
            </div>
        </div>
    )
}