import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {RouterProvider, createBrowserRouter } from 'react-router-dom';
import './fonts.css';
import './styles.css';
import {PageNotFound} from './Pages/PageNotFound';
import {Home} from './Pages/Home';
import Projects from './Pages/Projects';
import SubPage from './Pages/SubPage';
import {Voiceovers} from './Pages/Voiceovers';
import {Sketches} from './Pages/Sketches';
import {Edits} from './Pages/Edits';
import {ProjectPage} from './Pages/ProjectPage';
import {FullScreenVideo} from './Pages/FullScreenVideo';
import { CONSTANTS } from './Utility/Constants';
import {Info} from './Pages/Info';
import { Images } from './Utility/Images';
import { SartoriBraidoStudio } from './Pages/SartoriBraido';
import backImage from "./Media/Images/FallingPhoneImages/back.png";
import { GetWellSoon } from './Pages/GetWellSoon';

const router = createBrowserRouter([
  {
    path: '/sartoribraidostudio',
    element: <SubPage><SartoriBraidoStudio></SartoriBraidoStudio></SubPage>
  },{
    path: '/getwellsoon',
    element: <GetWellSoon />
  },{
    path: '/projectpage',
    element: <SubPage><ProjectPage /></SubPage>
  },{
    path: '/fullscreenvideo',
    element: <SubPage><FullScreenVideo /></SubPage>
  },{
    path: '/info',
    element: <SubPage backgroundImage={Images.INFO_BACK}><Info /></SubPage>
  },{
    path: '/',
    element: <Home />
  },{
    path: '*',
    element: <PageNotFound />
  },{
    path: '/projects',
    element: <SubPage><Projects /></SubPage>
  },{
    path: '/voiceovers',
    element: <SubPage backgroundImage={Images.BACKGROUND_RADIC}><Voiceovers /></SubPage>
  },{
    path: '/sketches',
    element: <SubPage backgroundImage={backImage}><Sketches /></SubPage>
  },{
    path: '/edits',
    element: <SubPage><Edits /></SubPage>
  }
  ]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>,
  document.getElementById('root')
);