import WELCOMEnote from "../Media/Images/WELCOMEnote.mp3";
import BrokenGlassSound from "../Media/Images/breakGlass.mp3";
import EnDi from '../Media/Images/DirectionACTIVE.mp3';
import DiDi from '../Media/Images/DirectionUNABLE.mp3';
import Sparks from '../Media/Images/SPARKS.mp3';

export const Audios = {
    HOME_SOUND: WELCOMEnote,
    BROKEN_GLASS: BrokenGlassSound,
    EnDi: EnDi,
    DiDi: DiDi,
    SPARKS: Sparks
}