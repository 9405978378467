import React, { ReactNode, useEffect, useState } from "react";
import { AppBar, Button, MenuList, MenuListItem, Toolbar } from "react95";
import { Icons } from "../Utility/Icons";
import { ThemeProvider } from "styled-components";
import original from 'react95/dist/themes/original';
import UP2 from '../Media/Images/UPoff.png';

export const WinAppBar: React.FC<any> = (props: any) => {
    const [open, setOpen] = useState(false);
    const [downEffect, setDownEffect] = useState(1);
    const [title, setTitle] = useState("");
    const [fontSize, setFontSize] = useState(0);
    const [listIcon, setListIcon] = useState("Icons.FileList");
    const [blink, setBlink] = useState(true);

    const handleState = (state: number, id: number) => {
        console.log("HS: " + state + " ID: " + id)
        if (props.status !== undefined) props.status(state, id);
    }

    const setParentUrl = (url: string) => {
        props.setSketchesUrl(url);
        setTitle(url.split('/')[url.split('/').length - 1].split('?')[0].split('.')[0].replaceAll("-", " "));
    }

    const handleResize = () => {
        setFontSize(window.innerWidth / 32);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    useEffect(() => {
        if (open) {
            setListIcon(Icons.FileList);
        }
    },[open]);

    useEffect(() => {
        if (blink) {
            const intervalId = setInterval(() => {
                setListIcon(currentImage => currentImage === Icons.FileListA ? Icons.FileListB : Icons.FileListA);
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [blink]);

    const nod: ReactNode[] = [
        <Button
            onClick={() => { setOpen(!open); setBlink(!blink); handleState(!open ? 1 : 0, 0) }}
            active={open}
            style={{ display: "block", margin: "5px", fontSize: "1rem", cursor: "url('" + Icons.PointerHand + "'),auto", fontFamily: "'MyFont'" }}
        >
            <img
                src={listIcon}
                alt='List File'
                style={{ height: "32px", cursor: "url('" + Icons.PointerHand + "'),auto" }}
            />
        </Button>,
        <Button
            onMouseOver={() => setDownEffect(0)}
            onMouseOut={() => setDownEffect(1)}
            onClick={() => window.location.href = props.url}
            style={{ display: "block", margin: "5px", fontSize: "1rem", cursor: "url('" + Icons.PointerHand + "'),auto", fontFamily: "'MyFont'" }}>

            <img
                src={Icons.Download}
                alt='Download'
                style={{ height: '32px', filter: "grayscale(" + downEffect + ")", cursor: "url('" + Icons.PointerHand + "'),auto" }}
            /></Button>];

    return (
        //add style to hide scrollbar
        <ThemeProvider theme={original}>
            <AppBar style={{ zIndex: 9998, top: "auto", bottom: 0, position: "fixed", width: "100%" }}>
                <Toolbar style={{ justifyContent: props.side ?? 'right' }}>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {props.side == "right" && <div style={{ height: '32px', marginRight: "5px", alignItems: "center", marginLeft: "5px", display: "flex", fontFamily: "'MyFont'" }}>
                                {title}
                            </div>}
                            {props.side == "right" ? nod[1] : nod[0]}
                            {props.side == "right" ? nod[0] : nod[1]}
                            {props.side != "right" && <div style={{ height: '32px', marginRight: "5px", alignItems: "center", marginLeft: "5px", display: "flex", fontFamily: "'MyFont'" }}>
                                {title}
                            </div>}
                        </div>
                        {open && (

                            <MenuList
                                style={{
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    scrollbarWidth: "none",
                                    msOverflowStyle: "none", // For Internet Explorer and Edge
                                    '&::-webkit-scrollbar': { // For Chrome, Safari and Opera
                                        display: 'none'
                                    },
                                    maxHeight: "80vh",
                                    maxWidth: "90vw",
                                    position: 'absolute',
                                    right: props.side == "right" ? '10px' : null,
                                    left: props.side == "left" ? '10px' : null,
                                    bottom: '5vh'
                                }}
                                onClick={() => setOpen(false)}
                            >
                                <div style={{ display: "flex", margin:"1vh",justifyContent: "center", width: "100%" }}>
                                    <img
                                        src={Icons.ScrollyUp}
                                        style={{ width:"10%" }}
                                    />
                                </div>
                                {props.list.map((x: any, i: number) => <MenuListItem style={{ padding: (2 * fontSize / 5).toString() + "px " + (fontSize / 2).toString() + "px " + (2 * fontSize / 5).toString() + "px " + (fontSize / 2).toString() + "px", fontFamily: "'MyFont'", fontSize: (2 * fontSize / 5).toString() + "px" }} onClick={() => { props.status ? handleState(2, 0) : (props.label == "Sketches" ? setParentUrl(x) : window.location.href = '/fullscreenvideo?url=' + x); }}>
                                    <img
                                        src={UP2}
                                        alt='react95 logo'
                                        style={{ transform: "rotate(90deg) scale(0.4)", height: fontSize.toString() + "px", marginRight: (fontSize / 2).toString() + "px" }}
                                    />
                                    {x.split('/')[x.split('/').length - 1].split('?')[0].split('.')[0].replaceAll("-", " ")}
                                </MenuListItem>)}
                                <div style={{ display: "flex", margin:"1vh",justifyContent: "center", width: "100%" }}>
                                    <img
                                        src={Icons.ScrollyDown}
                                        style={{ width: "10%" }}
                                    />
                                </div>
                            </MenuList>

                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}