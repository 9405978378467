import React from 'react';
import { Icons } from '../Utility/Icons';
import { CONSTANTS } from '../Utility/Constants';
import { ProjectPC } from '../Component/ProjectPC';
import { Images } from '../Utility/Images';

export default class Projects extends React.Component<any,any>{
    
    constructor(props:any){
        super(props);
    }
    
    render(): React.ReactNode {
        return (
            <div style={{color:"white", width:"100vw", height:"100vh", display: "flex",justifyContent: "center", alignItems: "center"}}>
                <img style={{position:"absolute",width: "100vw",height:"100vh"}} src={Images.BACKGROUND_PROJECTS}></img>
                <div style={{ width: "60vw", zIndex:"1000", position:"absolute",display: "flex", flexWrap:"wrap"}}>
                    <ProjectPC title="SOUP SERIES"/>
                    <ProjectPC />
                    <ProjectPC />
                    <ProjectPC />
                    <ProjectPC title="BROKE HISTORY"/>
                    <ProjectPC title="MOFO MAGIC RADIO"/>
                </div>
            </div>
        )
    }
}