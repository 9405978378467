import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../Utility/Constants";
import { RotatingProjLogo } from "./RotatingProjectLogo";
import { Images } from "../Utility/Images";
import { Icons } from "../Utility/Icons";

export const ProjectPC: React.FC<any> = (props: any) => {

    const image = Images.PC_ON;
    const [rotLogo, setRotLogo] = useState(false);
    const [factor, setFactor] = useState(1);

    const [fixed, setFixed] = useState(false);

    const over = () => {
        setRotLogo(true);
    }

    const handleFinish = (bool: boolean) => {
        setRotLogo(bool);
    }
    const handleResize = () => {
        if(window.innerWidth < 1000) setFactor(1.5);
        else setFactor(1);
    }

    useEffect(() => {
        if (window.innerWidth < window.innerHeight) {
            setRotLogo(true);
            setFixed(true);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    return (
        <div onMouseOver={over} onClick={props.title ? () => window.location.href = '/projectpage?proj=' + props.title : undefined} style={{ cursor: "url('" + (props.title ? Icons.PointerHand : Icons.Pointer) + "'), auto", fontFamily: "'MyFont', monospace", justifyContent: "center", alignItems: "center", display: "flex", width: "20vw"}}>
            <div style={{ position: "relative" }}>
                {rotLogo && <RotatingProjLogo factor={factor} fixed={fixed} title={props.title} set={handleFinish} />}
                <img style={{width: (13*factor).toString() + "vw"}} src={props.title ? image : Images.PC_OFF}></img>
                <div style={{ position: "relative", fontSize: (1.5*factor).toString()+"vw",top: "-"+(1.8*factor).toString()+"vw", color: "rgba(50,50,240)" }}>{props.title ?? "---"}</div>
                <div style={{ position: "relative", height: "2px",bottom:"1.6vh",width:"100%", backgroundColor: props.title ? "rgba(50,50,240)" : "rgba(0,0,0,0)" }}></div>
            </div>
        </div>
    )
}