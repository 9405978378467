import React, { useEffect, useState } from 'react';
import { Icons } from '../Utility/Icons';
import { CONSTANTS } from '../Utility/Constants';
import { FrameForVideo } from '../Component/FrameForVideo';
import { useSearchParams } from 'react-router-dom';
import { WinAppBar } from '../Component/WinAppBar';
import { EditsList } from '../Utility/ContentList';
import { Images } from '../Utility/Images';
import { ConvertDropboxUrl } from '../Utility/Utility';

declare const EditListBase: string[];

export const FullScreenVideo: React.FC<any> = (props: any) => {

    const edits = EditListBase === undefined ? EditsList : ConvertDropboxUrl(EditListBase);

    const [searchParams, setSearchParams] = useSearchParams();
    const url = searchParams.get('url');
    const videoId = "video019092";
    const [blur, setBlur] = useState("rgba(0,0,0,0)");
    const [played, setPlayed] = useState(false);
    const [show, setShow] = useState("none");
    const [size, setSize] = useState([600, 0]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [windowImg, setWindowImg] = useState("");

    const [evalWidth, setEvalWidth] = useState("");
    const [evalHeight, setEvalHeight] = useState("100vh");

    const [windowStyle, setWindowStyle] = useState<React.CSSProperties>({});
    const [videoStyle, setVideoStyle] = useState<React.CSSProperties>({});
    const [goFullScreenStyle, setGoFullScreenStyle] = useState<React.CSSProperties>({});

    const managePlay = (event: any) => {
        if (played) (event.target as HTMLVideoElement).pause();
        else (event.target as HTMLVideoElement).play();
        setPlayed(!played);
    }

    const evalScreenSize = (w: number, h: number) => {
        if (w > h) {
            //large, work with height
            setEvalWidth("");
            setEvalHeight("100vh");
        }
        else {
            setEvalWidth("100vw");
            setEvalHeight("");
        }

    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            evalScreenSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(size);
        const w = size[0];
        const h = size[1];
        if (width > height) {
            if (w != 0 && h != 0) {
                if (w / h > 2) {
                    setWindowImg(Images.WINDOW_EDITS_LARGE);
                    setVideoStyle({ left: "14.4%", width: "69%", top: "25%" });
                    setWindowStyle({ top: "5.5%", left: "13.5%", width: "72%" });
                    setGoFullScreenStyle({ width: "16.45%", height: "4.1%", left: "68.8%", top: "54.5%" });
                }
                else if (w / h > 1.7) {
                    setWindowImg(Images.WINDOW_EDITS);
                    setVideoStyle({ left: "14.4%", width: "69%", top: "20%" });
                    setWindowStyle({ top: "5.5%", left: "13.5%", width: "72%" });
                    setGoFullScreenStyle({ width: "16.45%", height: "4%", left: "68.8%", top: "58.95%" });

                }
                else if (w / h > 1.3) {
                    setWindowImg(Images.WINDOW_EDITS_43);
                    setVideoStyle({ left: "17%", width: "65%", top: "14.6%" });
                    setWindowStyle({ top: "6%", left: "16.5%", width: "68%" });
                    setGoFullScreenStyle({ width: "15.65%", height: "3.8%", left: "68.2%", top: "62.8%" });
                }
                else {
                    setWindowImg(Images.WINDOW_EDITS_SQUARE);
                    setVideoStyle({ left: "27.5%", width: "49%", top: "15.5%" });
                    setWindowStyle({ top: "10%", left: "10%", width: "82%" });
                    setGoFullScreenStyle({ width: "18.75%", height: "4.25%", left: "57%", top: "62.02%" });
                }
            }
        }
        else {
            if (w != 0 && h != 0) {
                if (w / h > 2) {
                    setWindowImg(Images.WINDOW_EDITS_LARGE);
                    setVideoStyle({ left: "14.4%", height: "29.5%", top: "26%" });
                    setWindowStyle({ top: "5.5%", left: "14%", height: "72%" });
                    setGoFullScreenStyle({ width: "16.45%", height: "4.1%", left: "68.8%", top: "54.5%" });
                }
                else if (w / h > 1.7) {
                    setWindowImg(Images.WINDOW_EDITS);
                    setVideoStyle({ left: "14.4%", height: "40%", top: "20%" });
                    setWindowStyle({ top: "5.5%", left: "14%", height: "72%" });
                    setGoFullScreenStyle({ width: "16.45%", height: "4%", left: "68.8%", top: "58.95%" });

                }
                else if (w / h > 1.3) {
                    setWindowImg(Images.WINDOW_EDITS_43);
                    setVideoStyle({ left: "19%", height: "43%", top: "18.6%" });
                    setWindowStyle({ top: "10.8%", left: "18.5%", height: "60%" });
                    setGoFullScreenStyle({ width: "15.65%", height: "3.8%", left: "68.2%", top: "62.8%" });
                }
                else {
                    setWindowImg(Images.WINDOW_EDITS_SQUARE);
                    setVideoStyle({ left: "26.8%", height: "43%", top: "20%" });
                    setWindowStyle({ top: "14%", left: "10%", height: "55%" });
                    setGoFullScreenStyle({ width: "18.75%", height: "4.25%", left: "57%", top: "62.02%" });
                }
            }
        }

        setShow("");
    }, [size,width,height]);

    return (
        <>
            <WinAppBar list={edits.filter((x:string) => x != "")} url={url} side="right" label="Video"></WinAppBar>
            <img style={{ position: "absolute", height: "100vh", width: "100vw" }} src={Images.BACKGROUND_DESK}></img>
            <div style={{ position: "relative", height: evalHeight, width: evalWidth, left: width <= 1024 ? "0%" : "-10%" }} >
                <img id="pp" style={{ cursor: "url('" + Icons.Pointer + "'),auto", bottom: width > height ? "2%" : "", position: "relative", height: width > height ? "95%" : "", width: width > height ? "" : "100%" }} src={Images.MONITOR_IMAGE}></img>
                <video autoPlay onLoadedMetadata={(event: any) => { setSize([event.target.videoWidth, event.target.videoHeight]) }} id={videoId} style={{ pointerEvents: "all", display: show, cursor: "url('" + Icons.PointerHand + "'),auto", position: "absolute", ...videoStyle }} onClick={(event: any) => managePlay(event)}>
                    <source src={url ?? ""}></source>
                </video>
                <img style={{ pointerEvents: "none", position: "absolute", ...windowStyle }} src={windowImg}></img>
                <div onClick={() => window.location.href = "/sartoribraidostudio"} style={{ cursor: "url('" + Icons.PointerHand + "'),auto", width: "8%", borderRadius: "50%", height: "11%", left: "87%", top: width > height ? "70.2%" : "75%", position: "absolute" }}></div>
                <div onMouseOver={() => setBlur("rgba(0,0,0,0.3)")} onMouseOut={() => setBlur("rgba(0,0,0,0)")} onClick={() => document.getElementById(videoId)?.requestFullscreen()} id='po' style={{ backgroundColor: "", cursor: "url('" + Icons.PointerHand + "'),auto", position: "absolute", ...goFullScreenStyle }}></div>
            </div >
        </>
    )
}