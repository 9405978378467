import React, { useState } from 'react';
import { WinAppBar } from '../Component/WinAppBar';
import { SketchesList } from '../Utility/ContentList';
import { ConvertDropboxUrl } from '../Utility/Utility';
import { FallingPhoneVid } from '../Component/FallingPhoneVid';

declare const SketchesListBase:string[];

export const Sketches: React.FC<{}> = () => {

    const sketches = SketchesListBase === undefined ? SketchesList : ConvertDropboxUrl(SketchesListBase);
    const [url, setUrl] = useState("");

    const handleUrl = (u: string) => {
        setUrl(u);
    }

    return (
        <div style={{ display: "flex", overflow: "hidden", justifyContent:"center" }}>
            <WinAppBar setSketchesUrl={handleUrl} url={url} list={sketches} label="Sketches" side="left" ></WinAppBar>
            <FallingPhoneVid url={url}></FallingPhoneVid>
        </div>
    )

}