import BROKE01 from "../Media/Images/BROKE/1.png"
import BROKE02 from "../Media/Images/BROKE/2.png"
import BROKE03 from "../Media/Images/BROKE/3.png"
import BROKE04 from "../Media/Images/BROKE/4.png"
import BROKE05 from "../Media/Images/BROKE/5.png"
import BROKE06 from "../Media/Images/BROKE/6.png"
import BROKE07 from "../Media/Images/BROKE/7.png"
import BROKE08 from "../Media/Images/BROKE/8.png"
import BROKE09 from "../Media/Images/BROKE/9.png"
import BROKE10 from "../Media/Images/BROKE/10.png"
import BROKE11 from "../Media/Images/BROKE/11.png"
import BROKE12 from "../Media/Images/BROKE/12.png"
import BROKE13 from "../Media/Images/BROKE/13.png"
import BROKE14 from "../Media/Images/BROKE/14.png"
import BROKE15 from "../Media/Images/BROKE/15.png"

import HUMAN01 from "../Media/Images/HUMAN/1.png"
import HUMAN02 from "../Media/Images/HUMAN/2.png"
import HUMAN03 from "../Media/Images/HUMAN/3.png"
import HUMAN04 from "../Media/Images/HUMAN/4.png"
import HUMAN05 from "../Media/Images/HUMAN/5.png"
import HUMAN06 from "../Media/Images/HUMAN/6.png"
import HUMAN07 from "../Media/Images/HUMAN/7.png"
import HUMAN08 from "../Media/Images/HUMAN/8.png"
import HUMAN09 from "../Media/Images/HUMAN/9.png"
import HUMAN10 from "../Media/Images/HUMAN/10.png"
import HUMAN11 from "../Media/Images/HUMAN/11.png"
import HUMAN12 from "../Media/Images/HUMAN/12.png"
import HUMAN13 from "../Media/Images/HUMAN/13.png"
import HUMAN14 from "../Media/Images/HUMAN/14.png"
import HUMAN15 from "../Media/Images/HUMAN/15.png"

import SOUP01 from "../Media/Images/SOUP/1.png"
import SOUP02 from "../Media/Images/SOUP/2.png"
import SOUP03 from "../Media/Images/SOUP/3.png"
import SOUP04 from "../Media/Images/SOUP/4.png"
import SOUP05 from "../Media/Images/SOUP/5.png"
import SOUP06 from "../Media/Images/SOUP/6.png"
import SOUP07 from "../Media/Images/SOUP/7.png"
import SOUP08 from "../Media/Images/SOUP/8.png"
import SOUP09 from "../Media/Images/SOUP/9.png"
import SOUP10 from "../Media/Images/SOUP/10.png"
import SOUP11 from "../Media/Images/SOUP/11.png"
import SOUP12 from "../Media/Images/SOUP/12.png"
import SOUP13 from "../Media/Images/SOUP/13.png"
import SOUP14 from "../Media/Images/SOUP/14.png"
import SOUP15 from "../Media/Images/SOUP/15.png"

import RADIO01 from "../Media/Images/RADIO/1.png"
import RADIO02 from "../Media/Images/RADIO/2.png"
import RADIO03 from "../Media/Images/RADIO/3.png"
import RADIO04 from "../Media/Images/RADIO/4.png"
import RADIO05 from "../Media/Images/RADIO/5.png"
import RADIO06 from "../Media/Images/RADIO/6.png"
import RADIO07 from "../Media/Images/RADIO/7.png"
import RADIO08 from "../Media/Images/RADIO/8.png"
import RADIO09 from "../Media/Images/RADIO/9.png"
import RADIO10 from "../Media/Images/RADIO/10.png"
import RADIO11 from "../Media/Images/RADIO/11.png"
import RADIO12 from "../Media/Images/RADIO/12.png"
import RADIO13 from "../Media/Images/RADIO/13.png"
import RADIO14 from "../Media/Images/RADIO/14.png"
import RADIO15 from "../Media/Images/RADIO/15.png"

export const RadioProjectImages = [
    RADIO01,RADIO02,RADIO03,RADIO04,RADIO05,
    RADIO06,RADIO07,RADIO08,RADIO09,RADIO10,
    RADIO11,RADIO12,RADIO13,RADIO14,RADIO15
]

export const SoupProjectImages = [
    SOUP01,SOUP02,SOUP03,SOUP04,SOUP05,
    SOUP06,SOUP07,SOUP08,SOUP09,SOUP10,
    SOUP11,SOUP12,SOUP13,SOUP14,SOUP15
]

export const HumanProjectImages = [
    HUMAN01,HUMAN02,HUMAN03,HUMAN04,HUMAN05,
    HUMAN06,HUMAN07,HUMAN08,HUMAN09,HUMAN10,
    HUMAN11,HUMAN12,HUMAN13,HUMAN14,HUMAN15
]

export const BrokeProjectImages = [
    BROKE01,BROKE02,BROKE03,BROKE04,BROKE05,
    BROKE06,BROKE07,BROKE08,BROKE09,BROKE10,
    BROKE11,BROKE12,BROKE13,BROKE14,BROKE15
]