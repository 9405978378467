import WindowGreen from "../Media/Images/WINDOW960x540.png";
import WindowGreenLarge from "../Media/Images/WINDOWorizon.png";
import WindowGreenSquare from "../Media/Images/windowSQUARE.png";
import WindowGreen43 from "../Media/Images/WINDOW4.3.png";
import WindowEdits from "../Media/Images/screenEDITS.png";
import WindowEditsLarge from "../Media/Images/screenEDITSwide.png";
import WindowEditsSquare from "../Media/Images/screenEDITSsquar.png";
import WindowEdits43 from "../Media/Images/screenEDITS4.3.png";
import Monitor from "../Media/Images/PCempty.png";
import BkDesk from "../Media/Images/bkDESK.png";
import Bk from "../Media/Images/bk.png";
import Bk1 from "../Media/Images/bk1.png";
import Bk2 from "../Media/Images/bk2.png";
import PcOn from "../Media/Images/PCon.png";
import PcOnClick from "../Media/Images/PConCLICK.png";
import PcOff from "../Media/Images/PCoff.png";
import FeelingOk from "../Media/Images/feelOK.png";
import BackgroundMonocolor from "../Media/Images/screen.png";
import Loading1 from '../Media/Icons/loading1.png';
import Loading2 from '../Media/Icons/loading2.png';
import Loading3 from '../Media/Icons/loading3.png';
import BrokenGlass from "../Media/Images/brokenglas.png";
import SartoriBraidoStudio from "../Media/Images/temple.png";
import InfoBack from "../Media/Images/sfondo1.png";
import Radic from "../Media/Images/radic.png";

import WindowAudio from "../Media/Images/AudioWindow/AUDIOwindoew.png";
import AudioPlay1 from "../Media/Images/AudioWindow/PLAYon.png";
import AudioPlay2 from "../Media/Images/AudioWindow/PLAYoff.png";
import AudioPause1 from "../Media/Images/AudioWindow/PAUSEon.png";
import AudioPause2 from "../Media/Images/AudioWindow/PAUSEoff.png";
import AudioMute1 from "../Media/Images/AudioWindow/MUTE.png";
import AudioMute2 from "../Media/Images/AudioWindow/SOUND.png";
import AudioStop1 from "../Media/Images/AudioWindow/STOPon.png";
import AudioStop2 from "../Media/Images/AudioWindow/STOPoff.png";

import BackStar1 from "../Media/Images/stars BK/1.png";
import BackStar2 from "../Media/Images/stars BK/2.png";
import BackStar3 from "../Media/Images/stars BK/3.png";
import BackStar4 from "../Media/Images/stars BK/4.png";
import BackStar5 from "../Media/Images/stars BK/5.png";

import Sparks1 from "../Media/Images/Spark/SPRK1.png";
import Sparks2 from "../Media/Images/Spark/SPRK2.png";
import Sparks3 from "../Media/Images/Spark/SPRK3.png";
import Sparks4 from "../Media/Images/Spark/SPRK4.png";
import Sparks5 from "../Media/Images/Spark/SPRK5.png";
import Sparks6 from "../Media/Images/Spark/SPRK6.png";

import WindowMichelangelo from "../Media/Images/WINDOWmichelangelo.png";

import EmptyPhone from "../Media/Images/FallingPhoneImages/86.png"

export const Images: any = {
    MONITOR_IMAGE: Monitor,
    WINDOW_IMAGE: WindowGreen,
    WINDOW_IMAGE_LARGE: WindowGreenLarge,
    WINDOW_IMAGE_SQUARE: WindowGreenSquare,
    WINDOW_IMAGE_43: WindowGreen43,
    WINDOW_IMAGE_MICHELANGELO: WindowMichelangelo,
    WINDOW_EDITS:WindowEdits,
    WINDOW_EDITS_LARGE:WindowEditsLarge,
    WINDOW_EDITS_SQUARE:WindowEditsSquare,
    WINDOW_EDITS_43:WindowEdits43,
    BACKGROUND_PROJECTS: Bk,
    BACKGROUND_VOICEOVER: Bk1,
    BACKGROUND_DESK: BkDesk,
    BACKGROUND_SKETCHES: Bk2,
    BACKGROUND_RADIC:Radic,
    PC_ON: PcOn,
    PC_ONCLICK: PcOnClick,
    PC_OFF: PcOff,
    FEELINGOK_IMAGE: FeelingOk,
    BACKGROUND_MONOCOLOR: BackgroundMonocolor,
    LOADING1: Loading1,
    LOADING2: Loading2,
    LOADING3: Loading3,
    BREAKGLASS: BrokenGlass,
    SARTORI_BRAIDO_STUDIO: SartoriBraidoStudio,
    INFO_BACK: InfoBack,
    WINDOW_AUDIO: WindowAudio
}

export const BackStars: string[] = [
    BackStar1, BackStar2, BackStar3, BackStar4, BackStar5
]

export const Sparks: string[] = [
    Sparks1,Sparks2,Sparks3,Sparks4,Sparks5,Sparks6
]

export const FallingPhoneImages:string[] = [
    EmptyPhone
];

export const AudioWindowImages: any = {
    WINDOW_AUDIO: WindowAudio,
    PLAY: AudioPlay1,
    STOP: AudioPlay2,
    PAUSE: AudioPause1,
    PAUSEOFF: AudioPause2,
    MUTE: AudioMute1,
    UNMUTE: AudioMute2,
    STOPON: AudioStop1,
    STOPOFF: AudioStop2
}