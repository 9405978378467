import React, { useEffect, useState } from "react";
import { HeadImages } from "../Utility/HeadImages";
import { CONSTANTS } from "../Utility/Constants";
import { Icons } from "../Utility/Icons";
import { SparksComponent } from "./Sparks";
import SpinEric from "../Media/Images/SpinERIC3.gif";

export const Head: React.FC<any> = (props: any) => {

    const [click, setClick] = useState(false);

    useEffect(() => {
        let timer:any;
        if(click) {
            timer = setTimeout(() => setClick(false), 1300);
        }
        return () => clearTimeout(timer); // Cleanup timeout
    }, [click]);

    return (
        <>
            {
                click &&
                <SparksComponent />
            }
            <img
                style={{ zIndex: "1", cursor: "url('" + Icons.PointerHand + "'),auto" }}
                onClick={() => setClick(true)}
                width={280}
                src={SpinEric}
                alt={`Head image`}
            />
        </>
    );
}
