import React, { useEffect, useState } from "react";
import { Icons } from "../Utility/Icons";
import { Images } from "../Utility/Images";

type LoadingImgProps = {
    isFin: () => void;
}

export const LoadingImg: React.FC<LoadingImgProps> = ({ isFin }) => {
    const [img, setImg] = useState(0);
    const loadingSequence: string[] = [Images.LOADING1, Images.LOADING2, Images.LOADING3];

    useEffect(() => {
        const timer = setTimeout(() => {
            setImg(prevImg => {
                if (prevImg === 2) {
                    isFin();
                    return 0;
                }
                return prevImg + 1;
            });
        }, 400);

        return () => clearTimeout(timer);
    }, [img]);

    return (
        <div
            style={{
                cursor: `url('${Icons.PointerWait}'), auto`,
                backgroundColor: "#000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                height: "100vh",
                position:"absolute",
                zIndex: "9999"
            }}
        >
            <img className="loadingImage" src={loadingSequence[img]} alt={`Loading step ${img + 1}`} />
        </div>
    );
}
