import Voiceover1 from "../Media/Audio/DemoReel1.mp3";

export const EditsList: string[]= [
    "",
    "https://www.dl.dropboxusercontent.com/scl/fi/6cckczpomixbomxvn3gb2/Lover-s-Call_Promo.mp4?rlkey=um346mw1vubgc7xt1j99hi9py&st=t6x9je5i&dl=1",
    "https://www.dl.dropboxusercontent.com/scl/fi/efetzfcqrh8x225pz8u8l/Man-Discovers-Fire_YOUTUBE.mp4?rlkey=yibf874notqscaww1yorrkpml&st=4p2o1aio&dl=1",
];

export const SketchesList: string[] = [
    "https://www.dl.dropboxusercontent.com/scl/fi/iuv7t00rugr363ku80y6f/The-Commodification-of-General-Reletivity.mp4?rlkey=ugy66kq94dw6ibmboqs67xd0d&st=rlxc2460&dl=1",
    "https://www.dl.dropboxusercontent.com/scl/fi/m77anslties3ydlandbc1/Garthax-Meets-Erc-Boi.mp4?rlkey=4ogo92240o0shgddfqjsewxr0&st=zvvd3cus&dl=1",
    "https://www.dl.dropboxusercontent.com/scl/fi/qmi3gmse1lx5mkf3zptdh/I-Have-A-Girlfriend.mov?rlkey=hem5aufhze9h5np622fr427pp&st=nb4buj91&dl=1",
    "https://www.dl.dropboxusercontent.com/scl/fi/qz6lq6de8u8mnn5y1y0pg/Out-of-Oatmilk.mp4?rlkey=swgdaqwup1t5ip0ycbe5zwqhf&st=u4snzji9&dl=1",
    "https://www.dl.dropboxusercontent.com/scl/fi/yl6488fkoq45adzsfsvof/Tiny-Item-Viewer.mp4?rlkey=06h0dbvybo5g7j17991jqellz&st=msxx8zuy&dl=1"
]

export const VoiceoverList: string[] = [
    ""
]