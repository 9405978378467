import React, { useState } from 'react';
import { DemoReel } from '../Component/DemoReel';
import DemoReel1 from '../Media/Audio/DemoReel1.mp3';
import { WinAppBar } from '../Component/WinAppBar';
import { VoiceoverList } from '../Utility/ContentList';
import { ConvertDropboxUrl } from '../Utility/Utility';

declare const VoiceoverListBase: string[];

export const Voiceovers: React.FC<any> = (props: any) => {
    const voiceovers: string[] = VoiceoverListBase === undefined ? VoiceoverList : ConvertDropboxUrl(VoiceoverListBase);

    const [recorder, setRecorder] = useState(0);
    const [id, setId] = useState<number | null>(null);

    const statefun = (s: number, id: number) => {
        if (recorder != 2) {
            setRecorder(s);
            if (s == 2) {
                (document.getElementById("audioSrc025") as HTMLMediaElement).play();
                setId(id);
            }
        }
        else if (s == 2) {
            (document.getElementById("audioSrc025") as HTMLMediaElement).pause();
            setId(null);
            setRecorder(0);
        }
    }

    const handleStop = (stop: boolean) => {
        if (recorder != 0) {
            if (stop) {
                setRecorder(1);
                (document.getElementById("audioSrc025") as HTMLMediaElement).pause();
            }
            else {
                setRecorder(2);
                (document.getElementById("audioSrc025") as HTMLMediaElement).play();
            }
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", color: "white" }}>
            <WinAppBar list={voiceovers} url={voiceovers[0]} side="right" label="Voiceover" status={statefun}></WinAppBar>
            <DemoReel stop={handleStop} status={recorder}></DemoReel>
            <audio id="audioSrc025" style={{ display: "none" }} src={DemoReel1}></audio>
        </div>
    )

}