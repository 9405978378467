import React from 'react';
import { EditsList } from '../Utility/ContentList';
import { CONSTANTS } from '../Utility/Constants';
import { FrameForVideo } from '../Component/FrameForVideo';
import { Images } from '../Utility/Images';
import { ConvertDropboxUrl } from '../Utility/Utility';

declare const EditListBase: string[];

export const Edits: React.FC<any> = (props: any) => {

    const edits = EditListBase === undefined ? EditsList : ConvertDropboxUrl(EditListBase);

    const generateRandomOffset = () => {
        const maxOffset = 10; // Maximum offset percentage
        return Math.random() * maxOffset - maxOffset / 2;
    };

    const generateRandomVertOffset = () => {
        const maxOffset = 25; // Maximum offset percentage
        return Math.random() * maxOffset - maxOffset / 2;
    };

    const lineMax = 2;

    //work on this. if vertical, use just 2 line and review vert offset
    const randomListBuild = (edits: string[]) => {
        var ed = 1;
        var list: string[][] = [];
        var tmp: string[] = []
        while (ed < edits.length) {
            if (ed == edits.length - 1) {
                if (tmp.length == 1) {
                    tmp.push(edits[ed]);
                    ed++;
                    list.push(tmp);
                }
                else{
                    tmp.push(edits[ed]);
                    tmp.push(edits[0]);
                    ed++;
                    list.push(tmp);
                }
            }
            else {
                if (Math.random() * edits.length < 1) {
                    tmp.push(edits[0])
                }
                else {
                    tmp.push(edits[ed]);
                    ed++;
                }

                if (tmp.length == lineMax) {
                    list.push(tmp);
                    tmp = [];
                }
            }


        }
        return list;
    }

    const list = randomListBuild(edits);
    console.log(list);
    const generateFrames = (list: any) => {
        return list.map((edits: string[], index: number) => (
            <div
                key={index}
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                {edits.map((edit: string, i: number) => (
                    <FrameForVideo
                        key={i}
                        url={edit}
                        style={{ left: generateRandomOffset().toString() + "vw", marginTop: index == 0 ? Math.abs(generateRandomVertOffset()).toString() + "vh" : generateRandomVertOffset().toString() + "vh"}}
                    />
                ))}
            </div>
        ));
    };

    return (
        <div style={{
            backgroundColor: "#408080",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden"
        }}>
            {generateFrames(list)}
        </div>

    );
};
