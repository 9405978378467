import React, { useState } from "react";
import { CONSTANTS } from "../Utility/Constants";
import { Icons } from "../Utility/Icons";
import '../styles.css';
import '../fonts.css';

export const InfoButton:React.FC<any> = (props:any) => {
    const [size,setSize] = useState(props.size);
    const [icon, setIcon] = useState(Icons.Info1);

    const click = () => window.location.href = '/info';
    const over = () => {     
        setIcon(Icons.Info2);
    }

    const out = () => { 
        setIcon(Icons.Info1);
    }

    return(
        <div className={props.className} style={{cursor:"url('"+Icons.PointerHand+"'), auto"}}>
            <img onClick={click} onMouseOver={over} onMouseOut={out} style={{cursor: "url('"+Icons.PointerHand+"'),auto"}} width={size} height={size*0.68} src={icon}></img>           
        </div>
    )
}