import Edits1 from '../Media/Icons/EDITSa.png';
import Edits2 from '../Media/Icons/EDITSb.png';
import Sketches1 from '../Media/Icons/SKETCHESa.png';
import Sketches2 from '../Media/Icons/SKETCHESb.png';
import DemoReel1 from '../Media/Icons/VOICEOVERa.png';
import DemoReel2 from '../Media/Icons/VOICEOVERb.png';
import SideProjects1 from '../Media/Icons/PROJECTSa.png';
import SideProjects2 from '../Media/Icons/PROJECTSb.png';
import Pointer from "../Media/Icons/ARROW.png";
import PointerWait from "../Media/Icons/ARROWwait.png";
import PointerHand from "../Media/Icons/clickHAND.png";
import HomeButton from '../Media/Icons/BAKb.png';
import HomeButton2 from '../Media/Icons/BAKc.png';
import BackButton from '../Media/Icons/BACKb.png';
import BackButton2 from '../Media/Icons/BACKc.png';
import Info1 from '../Media/Icons/Ainformation.png';
import Info2 from '../Media/Icons/Binformaion.png';
import Download from '../Media/Icons/download.png';
import FileList from '../Media/Icons/ListALPHA2.png';
import FileListA from '../Media/Icons/ListALPHA(A)a.png';
import FileListB from '../Media/Icons/ListALPHA(A)b.png';
import ScrollyUp from '../Media/Icons/SCROLLYup.png';
import ScrollyDown from '../Media/Icons/SCROLLYdown.png';
import MailA from '../Media/Icons/MAILA.png';
import MailB from '../Media/Icons/MAILB.png';
import XClose from '../Media/Icons/Xclose.png';

export const Icons: any = {
    MailA,
    XClose,
    MailB,
    ScrollyDown,
    ScrollyUp,
    FileList,
    FileListA,
    FileListB,
    Edits1,
    Edits2,
    Sketches1,
    Sketches2,
    DemoReel1,
    DemoReel2,
    SideProjects1,
    SideProjects2,
    Pointer,
    PointerWait,
    PointerHand,
    BackButton,
    BackButton2,
    HomeButton,
    HomeButton2,
    Download,
    Info1,
    Info2
}