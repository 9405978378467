import React from "react";
import { CONSTANTS } from "../Utility/Constants";
import { Icons } from "../Utility/Icons";

type ComputerScreenProps = {
    children: React.ReactNode
}

export const ComputerScreen: React.FC<ComputerScreenProps> = ({children}) => {
    
    return (
        <div style={{ width: "100vw", height: "100vh",cursor: "url('"+Icons.Pointer+"'), auto", backgroundColor: CONSTANTS.HOME_SCREEN_COLOR, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {children}
        </div>
    )
}
