import React from "react";
import { Audios } from "../Utility/Audios";
import Sparks from "../Media/Images/Sparks.gif";

export const SparksComponent: React.FC = () => {

    return (<>
        <audio autoPlay src={Audios.SPARKS} />
        <img
            width="80%"
            style={{ position: "absolute" }}
            src={Sparks}></img>
    </>
    )
}