import React, { useState } from "react";
import { CONSTANTS } from "../Utility/Constants";
import { Icons } from "../Utility/Icons";


type HomeButtonProps = {
    icon: string;
    icon2: string;
    position: "r" | "l";
    size: number;
    action: React.MouseEventHandler<HTMLImageElement>;
};

export const BackButton: React.FC<HomeButtonProps> = ({ icon, icon2, position, size, action }) => {
    const [currentIcon, setCurrentIcon] = useState(icon);

    const handleMouseOver = () => setCurrentIcon(icon2);
    const handleMouseOut = () => setCurrentIcon(icon);

    const containerStyle: React.CSSProperties = {
        cursor: `url('${Icons.PointerHand}'), auto`,
        left: position === "l" ? 0 : undefined,
        right: position === "r" ? 0 : undefined,
        top: 0,
        zIndex: 9999
    };

    const imageStyle: React.CSSProperties = {
        cursor: `url('${Icons.PointerHand}'), auto`
    };

    const handleclick = (event:any) => {
        action(event);
    }

    return (
        <div style={containerStyle}>
            <img
                onClick={handleclick}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={imageStyle}
                height={size}
                src={currentIcon}
                alt="Button"
            />
            
        </div>
    );
};
