import React, { useEffect, useState } from 'react';
import { AudioWindowImages, Images } from '../Utility/Images';
import { Icons } from '../Utility/Icons';
import { Button, ProgressBar } from "react95";
import { ThemeProvider } from "styled-components";
import original from 'react95/dist/themes/original';
import { AudioWindowButton } from './AudioWindowButton';

type AudioWindowProps = {
    src: string;
    style: React.CSSProperties;
    keyId: number;
}

export const AudioWindow: React.FC<AudioWindowProps> = ({ src, style, keyId }) => {

    const [playPressed, setPlayPressed] = useState(false);
    const [stopPressed, setStopPressed] = useState(true);
    const [pausePressed, setPausePressed] = useState(false);
    const [mutePressed, setMutePressed] = useState(false);

    const [percent, setPercent] = useState(0);
    const [scale, setScale] = useState(1.0);

    const [duration, setDuration] = useState("0:00");
    const [currentTime, setCurrentTime] = useState("0:00");

    const [size, setSize] = useState([10, 10]);


    useEffect(() => {
        let timer: NodeJS.Timeout;
    
        const updateAudioProgress = () => {
            const audioElement = document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement;
            if (audioElement) {
                setPercent((audioElement.currentTime / audioElement.duration) * 100);
                setCurrentTime(formatTime(audioElement.currentTime));
            }
            timer = setTimeout(updateAudioProgress, 500);
        };
    
        updateAudioProgress();
    
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const w = window.innerWidth;
            const h = window.innerHeight;
            setSize([window.innerWidth, window.innerHeight]);
            if(w > h){
                setScale(1.0);
            }
            
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatTime = (timeInSeconds: number) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const getAudioMetadata = (event: any) => {
        const currentTimeInSeconds = (event?.target as HTMLAudioElement).currentTime;
        const durationInSeconds = (event?.target as HTMLAudioElement).duration;

        setCurrentTime(formatTime(currentTimeInSeconds));
        setDuration(formatTime(durationInSeconds));
    };

    const playPressedAction = () => {
        if (!playPressed){
            (document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement)?.play();
            setPausePressed(false);
            setStopPressed(false);
            setPlayPressed(!playPressed);
        }
    }

    const stopPressedAction = () => {
        if (!stopPressed){
            (document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement)?.pause();
            (document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement).currentTime = 0;
            setPausePressed(false);
            setPlayPressed(false);
            setStopPressed(!stopPressed);
        }
    }

    const pausePressedAction = () => {
        if (!pausePressed){
            setPlayPressed(false);
            setStopPressed(false);
            (document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement)?.pause()
            setPausePressed(!pausePressed);
        }
    }

    const mutePressedAction = () => {
        if (mutePressed)
            (document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement).volume = 1
        else
            (document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement).volume = 0

        setMutePressed(!mutePressed);
    }

    return (<div style={{ ...style, position: "relative", display: "flex", width:"35vw",height:"27vh",cursor: "url('" + Icons.Pointer + "'), auto", justifyContent: "center" }}>
        <img style={{ width: "100%",position:"relative"}} src={Images.WINDOW_AUDIO} />
        <audio onLoadedMetadata={getAudioMetadata} id={"audioWindowSkp0" + keyId.toString()} src={src} style={{ display: "none"}} />
        <div style={{ fontSize: size[0] > size [1] ? "100%" : "2.5vh",fontFamily: "'MyFont'", position: "absolute", color: "white", top: "21%", left: "3%" }}>{src.split('/')[src.split('/').length - 1].split('?')[0].split('.')[0].replaceAll("-", " ")}</div>
        <div style={{ fontSize: size[0] > size [1] ? "100%" : "3vh",fontFamily: "'MyFont'", position: "absolute", color: "white", top: "6%", left: "10%" }}>Mofo Magic Radio</div>
        <div style={{width:"80%",top:"40%",position:"absolute"}}><ThemeProvider theme={original}>
            <ProgressBar
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    const rect = (event.target as HTMLDivElement).getBoundingClientRect();
                    const w = rect.width;
                    const x = (event.clientX - rect.left) < 0 ? 0 : (event.clientX - rect.left);
                    (document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement).currentTime = ((x / w) * ((document.getElementById("audioWindowSkp0" + keyId.toString()) as HTMLAudioElement)?.duration ?? 1));
                }}
                style={{ width: "100%", height:"6vh"}}
                value={Math.floor(percent)}
                hideValue
            />
        </ThemeProvider>
        </div>
        <div style={{fontSize:"1rem", fontFamily: "'MyFont'", color:"rgba(192,192,192,1)",position: "absolute", top: "47%", pointerEvents:"none" }}>{currentTime} / {duration}</div>
        <div style={{ position: "absolute", width: "90%", justifyContent: "center", display: "flex", bottom: "6%" }}>
            <AudioWindowButton imageOn={AudioWindowImages.PLAY} imageOff={AudioWindowImages.STOP} name="play" icon={Icons.PointerHand} size={size} pressed={playPressed} action={playPressedAction}></AudioWindowButton>
            <AudioWindowButton imageOn={AudioWindowImages.PAUSE} imageOff={AudioWindowImages.PAUSEOFF} name="pause" icon={Icons.PointerHand} size={size} pressed={pausePressed} action={pausePressedAction}></AudioWindowButton>
            <AudioWindowButton imageOn={AudioWindowImages.STOPON} imageOff={AudioWindowImages.STOPOFF} name="stop" icon={Icons.PointerHand} size={size} pressed={stopPressed} action={stopPressedAction}></AudioWindowButton>
            <ThemeProvider theme={original}>
        <Button
        active={mutePressed}
            onClick={mutePressedAction}
            style={{position:'absolute', width: size[0] > size [1] ? "10%" : "15%",height:"100%",right: size[0] > size [1] ? "3%" : "3%",cursor: "url('" + Icons.PointerHand + "'),auto" }}
            >
            <img
                src={mutePressed ? AudioWindowImages.MUTE : AudioWindowImages.UNMUTE}
                alt='play'
                style={{ height: '80%'  }}
            />
        </Button>
        </ThemeProvider>
        </div>
        
    </div>)
}