export const loadImage = (src: string) => {
    return new Promise<HTMLImageElement>((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
    });
};

export const preloadImages = async (source:string[],setImages?:any) => {
    const images = await Promise.all(source.map((src:string) => loadImage(src)));
    if(setImages !== undefined) setImages(images);
    return true;
};

export const loadVideo = (src: string) => {
    return new Promise<HTMLVideoElement>((resolve) => {
        const video = document.createElement('video');
        video.src = src;
        video.onloadeddata = () => resolve(video);
    });
};

export const preloadVideo = async (source:string[],setVideo?:any) => {
    const images = await Promise.all(source.map((src:string) => loadVideo(src)));
    if(setVideo !== undefined) setVideo(images);
    return true;
};

export const loadAudio = (src: string): Promise<HTMLAudioElement> => {
    return new Promise((resolve, reject) => {
        const audio = new Audio(src);
        audio.addEventListener('canplaythrough', () => resolve(audio), { once: true });
        audio.addEventListener('error', (e) => reject(e), { once: true });
    });
};

export const preloadAudio = async (sources: string[], setAudio?: (audios: HTMLAudioElement[]) => void): Promise<boolean> => {
    try {
        const audios = await Promise.all(sources.map((src) => loadAudio(src)));
        if (setAudio) setAudio(audios);
        return true;
    } catch (error) {
        console.error("Failed to preload audio:", error);
        return false;
    }
};

export const ConvertDropboxUrl = (list: string[]) => {
    return list.map((src: string) => src.replace("&dl=0","&dl=1").replace("www.dropbox.com","www.dl.dropboxusercontent.com"));
}
