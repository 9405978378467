import React, { useEffect, useState } from "react";
import { DemoReelImages } from "../Utility/DemoReelImages";
import { CONSTANTS } from "../Utility/Constants";
import { useSearchParams } from "react-router-dom";
import { Images } from "../Utility/Images";
import { Audios } from "../Utility/Audios";

import StopA from '../Media/Images/DemoReelImages/stopA.png';
import StopB from '../Media/Images/DemoReelImages/stopB.png';

import looping from '../Media/Images/DemoReelImages/LOOPINGtape2.gif';
import DRstop from "../Media/Images/DemoReelImages/OFF.png";
import DRon from "../Media/Images/DemoReelImages/1.png";

export const DemoReel: React.FC<any> = (props: any) => {

    const [img, setImg] = useState(0);
    const [broken, setBroken] = useState(false);
    const [stop, setStop] = useState(false);
    const [recStatus, setRecStatus] = useState(props.status);

    const handleBreak = () => {
        if (!broken) {
            setTimeout(() => setBroken(true), 100);
            (document.getElementById("AUD04984") as HTMLAudioElement).play()
        }

    }

    useEffect(()=> {
        setRecStatus(props.status);
    },[props.status]);

    useEffect(() => {
        props.stop(stop);
    }, [stop])

    return (
        <div style={{ marginRight: "25vw", position: "relative" }}>
            {recStatus == 2 && <img style={{ pointerEvents: 'none', maxWidth: "75vw", maxHeight: "90vh" }}
                src={looping}></img>}
            {/*<video
                style={{ clipPath:"polygon(14.2% 2.8%,13.9% 9%,6.5% 9%,4.2% 72%,5% 72%,4% 93%,12.5% 94.5%,90% 94.6%,91.8% 92%,87.5% 2.7%)",pointerEvents: 'none', maxWidth:"75vw",maxHeight:"90vh"}}
                autoPlay
                muted
                loop
                playsInline
                id="loopingVideo"
                onLoadedMetadata={(e) => {
                    e.currentTarget.play();
            }}
            >
                <source src={vid} type="video/mp4" />
            </video>*/}
            {recStatus == 1 && <img
                style={{ maxWidth: "75vw", maxHeight: "90vh" }}
                src={DRon}
                alt={`Demo reel frame ${img}`}
            />}
            {recStatus == 0 && <img
                style={{ maxWidth: "75vw", maxHeight: "90vh" }}
                src={DRstop}
                alt={`Demo reel frame ${img}`}
            />}
            <div onClick={handleBreak} style={{ position: "absolute", width: "40%", height: "50%", top: "10%", left: "42%", zIndex: 1 }} />
            {broken && <img
                style={{ position: "absolute", clipPath: "polygon(2% 0%,0% 100%,100% 100%,95% 0)", transform: "", width: "46.5%", height: "56%", top: "8%", left: "39.5%", zIndex: 1 }}
                src={Images.BREAKGLASS}
                alt="Broken Glass Overlay"
            />}
            <img
                style={{ left: "0%", position: "absolute", maxWidth: "75vw", maxHeight: "90vh" }}
                src={stop ? StopB : StopA}
                onClick={() => setStop(!stop)}
            ></img>
            <audio id="AUD04984" style={{ display: "none" }} src={Audios.BROKEN_GLASS} />
        </div>
    );
}
