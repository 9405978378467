import React, { useEffect, useState } from 'react';
import { Images } from '../Utility/Images';
import getWellSoon from '../Media/Images/getWell.gif';

export const GetWellSoon: React.FC<any> = (props: any) => {

    useEffect(() => {
        setTimeout(() => {
            window.history.back();
        }, 5000);
    }, []);

    return (
        <div style={{width:"100vw",height:"100vh",backgroundColor:"rgb(64,128,128)",display:"flex",justifyContent:"center",alignItems:"center"}}> 
            <img src={getWellSoon} style={{width:"60%"}}/>
        </div>

    )
}