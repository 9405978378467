import React from "react";

type AudioWindowButtonProps = {
    action: () => void;
    name: string;
    pressed: boolean;
    size: number[];
    icon: string;
    imageOn: string;
    imageOff: string;
}

export const AudioWindowButton: React.FC<AudioWindowButtonProps> = (props: AudioWindowButtonProps) => {

    return (
        <div
            onClick={props.action}
            style={{ margin: "0.3vw 1vw" }}>
            <img
                src={props.pressed ? props.imageOn : props.imageOff}
                alt={props.name}
                style={{ height: props.size[0] > props.size[1] ? '26px' : '18px', cursor: "url('" + props.icon + "'),auto" }}
            />
        </div>
    );
};