import React, { useEffect, useState } from "react";
import { SoupProjectImages,HumanProjectImages,BrokeProjectImages,RadioProjectImages } from "../Utility/ProjectLogos";
import { CONSTANTS } from "../Utility/Constants";

export const RotatingProjLogo: React.FC<any> = (props: any) => {

    const selectToUse = (str: string) => {
        switch (str) {
            case "MOFO MAGIC RADIO":
                return RadioProjectImages;
            case "SOUP SERIES":
                return SoupProjectImages;
            case "BROKE HISTORY":
                return BrokeProjectImages;
            case "HUMAN SHOW":
                return HumanProjectImages;
            default:
                return [];
        }
    }

    const touse:string[] = selectToUse(props.title);
    const [img, setImg] = useState(0);
    const [preloadedImages, setPreloadedImages] = useState<HTMLImageElement[]>([]);

    useEffect(() => {
        // Preload images
        const loadImage = (src: string) => {
            return new Promise<HTMLImageElement>((resolve) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve(img);
            });
        };

        const preloadImages = async () => {
            const images = await Promise.all(touse.map(src => loadImage(src)));
            setPreloadedImages(images);
        };

        preloadImages();
    }, []);

    useEffect(() => {
        var timer: any = null;
        if (img < (touse.length - 1))
            timer = setTimeout(() => {
                setImg(prevImg => (prevImg + 1 === touse.length ? 0 : prevImg + 1));
            }, CONSTANTS.ROTATING_HEAD_INTERVAL);
        else timer = setTimeout(() => props.set(false), props.fixed ? 10000 : 2000);

        return () => clearTimeout(timer); // Clear timeout if component unmounts or img changes
    }, [img]);

    return (
        <img
            onMouseOver={props.set(true)}
            style={{ width:(13*props.factor).toString() + "vw",position: "absolute" }}
            src={preloadedImages[img]?.src}
        />
    );
}
