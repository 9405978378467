import React, { useState } from "react";
import '../fonts.css';
import { CONSTANTS } from "../Utility/Constants";
import { Icons } from "../Utility/Icons";

export type HomeButtonProps = {
    className: string,
    icon: string,
    icon2: string,
    label: string
}

export const HomeButton: React.FC<HomeButtonProps> = (props: HomeButtonProps) => {

    const [scale, setScale] = useState(1);
    const [icon, setIcon] = useState(props.icon);

    const click = () => window.location.href = props.label.toLocaleLowerCase();
    
    const over = () => {
        setScale(CONSTANTS.HOME_ICON_MAGNIFY);
        setIcon(props.icon2);
    }

    const out = () => {
        setScale(1);
        setIcon(props.icon);
    }

    return (
        <div style={{pointerEvents:"all",cursor: "url('"+Icons.PointerHand+"'),auto",transform: "scale("+scale+")"}} onClick={click} onMouseOver={over} onMouseOut={out} className={props.className}>
            <img width={CONSTANTS.HOME_ICON_SIZE} src={icon} alt={props.label} />
            <span>{props.label}</span>
        </div>
    )
}