import React, { useEffect, useState } from 'react';
import { Icons } from '../Utility/Icons';
import { ComputerScreen } from '../Component/ComputerScreen';
import { Head } from '../Component/Head';
import '../fonts.css';
import { InfoButton } from '../Component/InfoButton';
import { HomeButton, HomeButtonProps } from '../Component/HomeButton';
import { HomeButtonContainer } from '../Component/HomeButtonContainer';
import { preloadAudio, preloadImages } from '../Utility/Utility';
import { HeadImages } from '../Utility/HeadImages';
import { Audios } from '../Utility/Audios';
import { BackStars, Images, Sparks } from '../Utility/Images';
import { LoadingImg } from '../Component/LoadingImg';
import { useSearchParams } from 'react-router-dom';
import { StarsBackground } from '../Component/StarsBackground';


export const Home: React.FC<any> = (props: any) => {

    const preload = async () => {
            preloadImages(Object.values(BackStars))
            preloadImages(Object.values(Sparks))
            //preloadImages(Object.values(Images))
            //preloadImages(Object.values(Icons))
            //preloadAudio(Object.values(Audios))
    }

    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        preload();
    }, []);

    const homeButtons: HomeButtonProps[] = [
        {
            className: "button top-left",
            icon: Icons.SideProjects1,
            icon2: Icons.SideProjects2,
            label: "Projects"
        }, {
            className: "button top-right",
            icon: Icons.Sketches1,
            icon2: Icons.Sketches2,
            label: "Sketches"
        }, {
            className: "button bottom-left",
            icon: Icons.DemoReel1,
            icon2: Icons.DemoReel2,
            label: "Voiceovers"
        },
        {
            className: "button bottom-right",
            icon: Icons.Edits1,
            icon2: Icons.Edits2,
            label: "Edits"
        }
    ]

    const checkFin = () => {
        setIsLoading(false);
    };

    return (<>
        <audio id="AUD0498" style={{ display: "none" }} autoPlay src={Audios.HOME_SOUND} />
            <ComputerScreen>
                <StarsBackground />
                <HomeButtonContainer>
                    {homeButtons.map((x: HomeButtonProps) => <HomeButton className={x.className} icon={x.icon} icon2={x.icon2} label={x.label} />)}
                </HomeButtonContainer>
                <Head/>
                <InfoButton className="infoButton" />
            </ComputerScreen>
            {loading && <div style={{position:"absolute", top:0,left:0}}><LoadingImg isFin={checkFin}></LoadingImg></div>}
    </>
    )
}