import React from "react";
import { HomeButtonProps } from "./HomeButton";

type HomeButtonContainerProps = {
    children: React.ReactNode
}

export const HomeButtonContainer: React.FC<HomeButtonContainerProps> = ({ children }) => {

    return (
        <div style={{ pointerEvents:"none",zIndex: 10, position: "absolute", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {children}
        </div>
    )
}