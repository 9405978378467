import React, { useEffect, useState } from "react";
import { FallingPhoneImages } from "../Utility/Images";
import { Videos } from "../Utility/Videos";

type FallingPhoneVidProps = {
    url: string;
};

export const FallingPhoneVid: React.FC<FallingPhoneVidProps> = ({url}) => {
    const [video, setVideo] = useState(false);

    useEffect(() => {
        if (url !== "") setVideo(true);
    }, [url])

    return (
        <div style={{ height:"100vh", position: 'relative' }}>
            {video && 
                <><video
                    key={url}
                    autoPlay
                    style={{
                        position: 'absolute',
                        pointerEvents: 'all',
                        top: '20.3%',
                        left: '40.8%',
                        width: '15.7%',
                        aspectRatio: '0.57179778'
                    }}
                    controls
                >
                    <source src={url} type="video/mp4" />
                </video>
                <img
                style={{ pointerEvents: 'none', height: '100%', position: 'relative' }}
                src={FallingPhoneImages[0]}
                alt={`Falling phone`}
            />
            </>
            }
            {!video &&
                <video
                style={{ pointerEvents: 'none', height: '100%', position: 'relative' }}
                autoPlay
                muted
                playsInline
                onLoadedMetadata={(e) => e.currentTarget.play()}
            >
                <source src={Videos.sketchesVideo} type="video/mp4" />
            </video>
            }
        </div>

    );
};
